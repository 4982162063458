export function saveInLocalStorage(key: string, value: unknown, defaultValue?: string) {
  let valueStr = defaultValue || '';
  try {
    valueStr = JSON.stringify(value);
  } catch (err) {}
  localStorage.setItem(key, valueStr);
}

export function getFromLocalStorage<T>(key: string) {
  if (typeof window === 'undefined') return null;

  const valueStr = localStorage.getItem(key) || '';
  if (valueStr) {
    // parse local storage cache
    try {
      const cachedVal = JSON.parse(valueStr) || null;
      if (cachedVal) {
        return cachedVal as T;
      }
    } catch (err) {}
  }
  return null;
}
