import muiGrey from '@mui/material/colors/grey';
import muiBlue from '@mui/material/colors/blue';
import muiGreen from '@mui/material/colors/green';
import muiYellow from '@mui/material/colors/yellow';
import muiRed from '@mui/material/colors/red';
import muiBlueGrey from '@mui/material/colors/blueGrey';
import muiOrange from '@mui/material/colors/orange';

export const grey = muiGrey;
export const blue = muiBlue;
export const green = muiGreen;
export const yellow = muiYellow;
export const red = muiRed;
export const blueGrey = muiBlueGrey;
export const orange = muiOrange;
