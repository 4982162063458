import { LanguageCodes, Language, Languages } from '@repo/entities/PersonEntity';
import { i18next } from '@repo/translation/i18next';
import { useTranslation } from '@repo/translation/react-i18next';
import { useUpdateLanguage } from '../utils/language.ts';
import { Select } from './Select.tsx';

export const LanguageSelect = ({
  saveLanguageToRemote = true,
}: {
  saveLanguageToRemote?: boolean;
}) => {
  const { t } = useTranslation('workerAppBar');
  const { updateLanguage } = useUpdateLanguage();

  const inputValue = Object.entries(LanguageCodes).find(
    (item) => item[1] === i18next.language,
  )?.[0] as string;

  const handleSelectLanguage = (val: Language) => {
    updateLanguage(val, saveLanguageToRemote);
  };

  return (
    <Select
      label='Language'
      value={inputValue}
      onChange={(val) => handleSelectLanguage(val as Language)}
      translationFn={(item) => t(`languages:${item}`)}
      items={Languages.map((item) => ({ value: item, label: t(`languages:${item}`) }))}
      size='small'
      variant='outlined'
      style={{ width: 130 }}
      hiddenLabel
    />
  );
};
